import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import {
  getAllProposal,
  getSendFundContract,
  getPioneerDaoContract,
  getDesignDaoContract,
  getAuctionContract,
  getMainDaoContract,
  getDevFundContract,
} from "../../utils/contractHelpers.js";
import Environment from "../../utils/Environment";
const UserExecute = () => {
  const web3 = useWeb3();
  const contractAddress = Environment.CreateProposal;
  const contract = getAllProposal(contractAddress, web3);
  const execute = useCallback(
    async (id, account) => {
      // let contract = ''s
      // if (address === Environment.TomiTokenDao.toLowerCase()) {
      //     console.log("here");
      //     contract = getTomiTokenProposalContract(address, web3);
      // } else if(address === Environment.TomiFundsDao.toLowerCase()) {
      //     console.log("here1");
      //     contract = getSendFundContract(address, web3);
      // } else if(address === Environment.PioneerNftDao.toLowerCase()){
      //     contract = getPioneerDaoContract(address, web3);
      // } else if(address === Environment.TomiDesignDao.toLowerCase()){
      //     contract = getDesignDaoContract(address, web3);
      // } else if(address === Environment.TomiAuctionDao.toLowerCase()){
      //     contract = getAuctionContract(address, web3);
      // } else if(address === Environment.TomiMainDao.toLowerCase()){
      //     contract = getMainDaoContract(address, web3);
      // } else if(address === Environment.TomiDevFunds.toLowerCase()) {
      //     console.log("here2",address);
      //     contract = getDevFundContract(address, web3);
      // }
      let Arr = [
        "",
        "executeChangeEmission",
        "executeUpdateWallet",
        "executeUpdateWallet",
        "executeUpdateWallet",
      ];
      Arr[16] = "executeSendToken";
      Arr[17] = "executeSendToken";
      Arr[18] = "executeSendToken";
      Arr[19] = "executeSendNFT";
      Arr[5] = "executeSendNFT";
      Arr[6] = "executeBlockNFTWallet";
      Arr[7] = "executeChangeCommittee";
      Arr[8] = "executeChangeCommittee";
      Arr[9] = "executeChangeAuction";
      Arr[10] = "executeChangeAuction";
      Arr[11] = "executeChangeAuction";
      Arr[32] = "executeContentModeration";
      Arr[12] = "executeChangeDao";
      Arr[13] = "executeChangeDao";
      Arr[14] = "executeChangeDao";
      Arr[15] = "executeChangeDao";
      Arr[20] = "executeSendTokenDev";
      Arr[21] = "executeSendTokenDev";
      Arr[22] = "executeSendTokenDev";

      // let a = Arr[selectedFun]
      // a.push(NFtID)

      console.log("sdfsfdsfdsf", id, account);
      try {
        // let gasPrice = await web3.eth.getGasPrice();
        // // let gasPrice2 = parseInt(gasPrice) + 50000000000
        // const gas = await contract.methods.execute(id).estimateGas({ from: account });
        const details = await contract.methods.execute(id).send({
          from: account,
          // gas,
          // gasPrice: gasPrice,
        });
        return details;
      } catch (error) {
        console.log("catchhhh", error);
        throw error;
      }
    },
    [web3]
  );
  return { execute: execute };
};
export default UserExecute;
