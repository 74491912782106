import { useWeb3React } from "@web3-react/core";
import { useCallback } from "react";
// import Web3 from "web3";
import { getLibraryForSign } from "../../utils/web3React";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useWeb3 from "../../hooks/useWeb3";
import { connectorsByName } from "../../utils/web3React";
import { ethers } from "ethers";
export const Signature = (data) => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  var library = null;
  const connectorId = window.localStorage.getItem("connectorId");
  if (connectorId === "injected" && account) {
    library = getLibraryForSign(web3?.givenProvider);
  } else {
    // return console.log("connectorsByName", connectorsByName['walletconnect'])
    if (connectorsByName["walletconnect"].walletConnectProvider) {
      library = getLibraryForSign(connectorsByName.walletconnect.provider);
    }
  }
  const sign = useCallback(async () => {
    if (library && account) {
      try {
        if (account) {
          const connectorId = window.localStorage.getItem("connectorId");
          console.log("sdfdfsdfdsf", connectorId);
          // connectorsByName['walletconnect'].walletConnectProvider;
          if (connectorId === "injected") {
            library = getLibraryForSign(web3.givenProvider);
          } else {
            library = getLibraryForSign(connectorsByName.walletconnect.provider);
          }
          let signature = await library.send("personal_sign", [
            ethers.utils.hexlify(
              ethers.utils.toUtf8Bytes(JSON.stringify({ address: account, name: "Tomi Dao" }))
            ),
            account.toLowerCase(),
          ]);
          // let signature = await signing?.signMessage(web3.utils.hexToBytes(web3.utils.stringToHex(JSON.stringify({address : account , name : "Tomi Dao"}))) );
          console.log("sign", signature);
          toast.success(`${account.substring(0, 6)}...${account.substring(account.length - 4)}`, {
            position: "top-right",
            autoClose: 2000,
          });
          return signature;
        }
      } catch (error) {
        console.log("error", error);
        toast.error(error && error.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    }
  }, [account, library, data]);
  return { userSign: sign };
};
export default Signature;
