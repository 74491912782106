import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getTomiContract } from "../../utils/contractHelpers.js";
const UserDelegate = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.TomiTokenContract;
  const contract = getTomiContract(tokenAddress, web3);
  const del = useCallback(
    async (account) => {
      // let a=[]
      // a.push(NFtID)
      //   console.log("sdfsfdsfdsf", id, support, account, NFtID, contract);
      try {
        let gasPrice = await web3.eth.getGasPrice();
        let gasPrice2 = parseInt(gasPrice) + 50000000000;
        const gas = await contract.methods.delegate(account).estimateGas({ from: account });
        const details = await contract.methods.delegate(account).send({
          from: account,
          gas,
          gasPrice: gasPrice2,
        });
        return details;
      } catch (error) {
        console.log("catchhhh", error);
        throw error;
      }
    },
    [contract]
  );
  return { del: del };
};
export default UserDelegate;
